<template>
  <div>
    <common-header></common-header>
    <div class="tab-box" v-if="das.useConditionAmount == ''">
      <div class="item" :class="tabIndex == 0 ? 'active' : ''" @click="tabChange(0)">未使用</div>
      <div class="item" :class="tabIndex == 1 ? 'active' : ''" @click="tabChange(1)">已使用</div>
      <div class="item" :class="tabIndex == 2 ? 'active' : ''" @click="tabChange(2)">已失效</div>
    </div>

    <ul class="coupons-list warp">
      <li class="item" v-for="(item, index) in list" :key="index" :class="item.status == 0 && tabIndex == 0 ? '' : 'used'" @click="choise(index)">
        <div class="left">
          <div class="price">
            <span class="unit">￥</span>
            <span class="money">{{item.amount}}</span>
          </div>
          <div class="ms">满{{item.useConditionAmount}}元可用</div>
        </div>
        <div class="border"></div>
        <div class="right">
          <div class="title">{{item.remarks}}</div>
          <div class="time">{{(item.createTime).substring(0,11)}}至{{(item.expireTime).substring(0,11)}}</div>
        </div>
        <div class="check" v-if="das.useConditionAmount > 0" :class="checkIndex == index ? 'active' : ''"></div>
      </li>
    </ul>
    <no-data v-if="nodata"></no-data>
    <div class="bottom-box">
      <router-link to="/account/ptcoupons" class="fixed-bottom jb-r-l">领取更多优惠券</router-link>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      tabIndex: 0,
      das: {
        // hasExpire: false, //	是否已过期
        useConditionAmount: "",
        status: 0, //是否已使用
        hasExpire: false,
      },
      id: "",
      list: [],
      nodata: false,
      checkIndex: 0,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.das.useConditionAmount = this.$route.query.useConditionAmount;
    }
    this.init(this.das);
  },
  methods: {
    // 选择优惠券
    choise(i) {
      if (this.id == '') {
        return false;
      } else {
        localStorage.setItem('CouponId', this.list[i].id);
        this.$router.go(-1);
      }
    },
    tabChange(i) {
      this.list = [];
      let das = this.das;
      if (i == 0) {
        das = {
          status: 0,
          hasExpire: false,
        }
      } else if (i == 1) {
        das = {
          status: 1
        }
      } else if (i == 2) {
        das = {
          hasExpire: true,
        }
      }
      this.tabIndex = i;
      this.init(das);
    },
    init(da) {
      this.getMyCoupons(da).then((res)=> {
        this.nodata = res.data.length > 0 ? false : true;
        this.list = res.data;
        this.list.forEach((e, i)=> {
          if (e.id == this.id) {
            this.checkIndex = i;
          }
        })
      })
    },
    ...mapActions('account', [
      "getMyCoupons",
    ])
  },
}
</script>
<style lang='less' scoped>
.bottom-box{
  height: .88rem;
}
.fixed-bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: .88rem;
  color: #fff;
  text-align: center;
}
.check{
  width: .38rem;
  height: .38rem;
  border-radius: 50%;
  border: .02rem solid #d6d6d6;
  margin-right: .2rem;
  &.active{
    background:url('../../assets/images/img/check-active.png') no-repeat;
    background-size: 100%;
    border: none;
  }
}
.coupons-list{
  padding-top: .2rem;
  .item{
    border-radius: .05rem;
    height: 1.73rem;
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: .2rem;
    color: #ff7241;
    &.used{
      color: #666666;
      background: #e7e7e7;
    }
    .right{
      font-size: .3rem;
      padding-left: .4rem;
      .time{
        font-size: .22rem;
        margin-top: .05rem;
        padding-left: .1rem;
      }
    }
    .border{
      position: absolute;
      width: 1px;
      height: 1.21rem;
      background: #ff7241;
      opacity: 0.1;
      top: 50%;
      margin-top: -.6rem;
      left: 2.12rem;
    }
    .left{
      width: 2.12rem;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      &::after{
        position: absolute;
        top: -.125rem;
        right: -.125rem;
        width: .25rem;
        height: .25rem;
        background: #f7f7f7;
        border-radius: 50%;
        content: "";
      }
      &::before{
        position: absolute;
        bottom: -.125rem;
        right: -.125rem;
        width: .25rem;
        height: .25rem;
        background: #f7f7f7;
        border-radius: 50%;
        content: "";
      }
      .ms{
        font-size: .26rem;
      }
      .price{
        .unit{
          font-size: .26rem;
        }
        .money{
          font-size: .6rem;
          font-weight: bold;
        }
      }
    }
    .right{
      flex: 1;
    }
  }
}
.tab-box{
  display: flex;
  background: #ffffff;
  .item{
    flex: 1;
    text-align: center;
    height: 1rem;
    line-height: 1rem;
    font-size: .3rem;
    &.active{
      font-weight: bold;
      position: relative;
      color: #ff7241;
      &::after{
        position: absolute;
        bottom: 0;
        left: 50%;
        width: .85rem;
        height: .04rem;
        background: #ff7241;
        content: "";
        margin-left: -0.425rem;
      }
    }
  }
}
</style>